import React, { useContext, useEffect, useState } from 'react'
import { Gen2Context } from '../contexts/Gen2Context'
import { Gen3Context } from '../contexts/Gen3Context'
import * as styles from './unbred-gen2.module.css'


export type ImageAttributes = {
	tokenId: number,
	price: number,
	name: string,
	image: string
}

type MatedEntry = {
	hasMated: boolean,
	expiration: number,
	closing: string,
	price: number
}

type MatedData = {
	info: {
		newMated: number,
		updatedAt: string
	},
	entries: {
		[id: number] : MatedEntry
	}
}

type MatedDataclip = {
	title: string,
	values: any[][]
}

export default function UnbredGen2() {

	const { contract: gen2Contract } = useContext(Gen2Context) || {}
	const { contract: gen3Contract } = useContext(Gen3Context) || {}

	const [images, setImages] = useState<ImageAttributes[]|null>(null)

	function deconstructTokenURI(uri: string, tokenId: number, price: number): ImageAttributes {
		let split = uri.substring(uri.indexOf(',') + 1)
		let decoded = Buffer.from(split, 'base64').toString()
		let metadata = JSON.parse(decoded)

		return {
			tokenId: tokenId,
			price: price,
			name: metadata.name as string,
			image: metadata.image as string
		}
	}

	async function enumerateUnbredBears() {
		console.log(`Enumerating unbred gen 2 bears`)
		let totalBears = await gen2Contract.methods.totalSupply().call()

		let added = 0
		let counted = 0
		let checkLast = (addedValue: number) => {
			if (++counted == totalBears) {
				if (addedValue == 0) {
					console.log(` - setting blank images`)
					setImages([])
				} else {
					console.log(` - finished: ${added}`)
				}
			}
		}

		// let json = await fetch("https://fierce-spire-98508.herokuapp.com/mated/v1")
		let json = await fetch("https://data.heroku.com/dataclips/tiotsvpimnaoyksnkrmstcsgajpr.json", { cache: "no-cache" })
		let wrapper = await json.json() as MatedDataclip // as MatedData
        let knownMated: MatedData = { info: { newMated: 0, updatedAt: "" }, entries: {} }
		knownMated.info = wrapper.values[0][0]
		knownMated.entries = wrapper.values[0][1]

		console.log(`Mated: ${Object.keys(knownMated.entries).length} entries updated at ${knownMated.info.updatedAt} (current unix time: ${Math.floor(Date.now() / 1000)})`)

		for (var tokenId = 0; tokenId < totalBears; tokenId++) {
			// Check the known list
			let known = knownMated.entries[tokenId]
			if (known !== undefined && known !== null && (known.hasMated || known.price == 0)) {
				checkLast(added)
				continue
			}
			// console.log(`known[${tokenId}] = ${known?.hasMated}`)

			let localTokenId = tokenId
			gen3Contract.methods.hasGen2Mated(localTokenId).call(async function(error: any, mated: boolean) {
				try {
					let currentEntry = knownMated.entries[localTokenId]
					if (error !== null) {
						console.error(error)
					} else if (mated) {
						console.log(`Skipping mated gen 2 bear: ${localTokenId}`)
					} else if (currentEntry === undefined || currentEntry.price <= 0) {
						console.debug(`Skipping gen 2 bear with no listing: ${localTokenId}`)
					} else if (currentEntry.expiration > 0 && currentEntry.expiration <= Math.floor(Date.now() / 1000)) {
						console.log(`Skipping expired entry: ${currentEntry.closing}`)
					} else {
						var uri = await gen2Contract.methods.tokenURI(localTokenId).call()
						const decodedTokenUri = deconstructTokenURI(uri, localTokenId, knownMated.entries[localTokenId].price)
						if (added++ == 0) {
							console.log(`Setting first item: ${added}`)
							setImages([decodedTokenUri])
						} else {
							setImages(old => {
								var result = [...old]
								const sortedIndex = (array: any[], value: ImageAttributes) => {
									var low = 0, high = array.length
									while (low < high) {
										var mid = (low + high) >>> 1
										if (array[mid].price === value.price) {
											if (array[mid].tokenId === value.tokenId) return mid
											if (array[mid].tokenId < value.tokenId) low = mid + 1
											else high = mid
										}
										else if (array[mid].price < value.price) low = mid + 1
										else high = mid
									}
									return low
								}
								const insert = sortedIndex(result, decodedTokenUri)
								if (insert < result.length && result[insert].tokenId === decodedTokenUri.tokenId) {
									result.splice(insert, 1, decodedTokenUri)
								} else {
									result.splice(insert, 0, decodedTokenUri)
								}
								return result
							})
						}
					}
				} catch (error) {
					console.error(error)
				}
				checkLast(added)
			})
		}
	}

	useEffect(() => {
		if (gen2Contract === null || gen3Contract === null) {
			setImages(null)
		} else {
			enumerateUnbredBears()
		}
	}, [gen2Contract, gen3Contract])

	return (
		<>
			{images == null ? <p>Searching unbred bears...</p> :
				images.length == 0 ? <p>No unbred bears for sale on OpenSea</p> :
				<ul>
					{images.map((bear) =>
						<li className={styles.cellItem} key={bear.tokenId}>
							<a href={`https://opensea.io/assets/${gen2Contract.options.address}/${bear.tokenId}`} target="_blank">
							<img src={bear.image} alt="Unbred Gen 2 Bear"/>
							<p className="selected"><b>Ξ{bear.price}</b>: {bear.name}</p>
							</a>
						</li>
					)}
				</ul>
			}
		</>
	)
}
