import Layout from '../components/layout'
import React from 'react'
import { Gen3Provider } from '../contexts/Gen3Context'
import { Gen2Provider } from '../contexts/Gen2Context'
import UnbredGen2 from '../components/unbred-gen2'
import { Link } from 'gatsby'

export default function IndexPage() {

	return (
		<Layout prompt="" custom="The safest way to Find Unbred Bears in the Two Bit Bears Extended Universe™️" altLogo={true}>
			<p className="alert">Or search by token <Link to="/gen-2-parent-inventory/">here</Link>.</p>
			<Gen3Provider>
				<Gen2Provider>
					<UnbredGen2/>
				</Gen2Provider>
			</Gen3Provider>
		</Layout>
    )
}
